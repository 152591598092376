<template>
  <div v-if="product" class="product-detail">
    <!-- 新版header -->

    <v-container class="mainProduct--box relative">
      <v-row>
        <v-col cols="6">
          <div class="d-block">
            <template v-if="allSizePhotoList.length > 1">
              <v-carousel :show-arrows="false" height="auto">
                <v-carousel-item
                  v-for="(photo, index) in allSizePhotoList"
                  :key="index"
                >
                  <v-img
                    class="mainProduct--image"
                    :lazy-src="photo.tinyPhotoUrl"
                    :src="photo.originPhotoUrl"
                    aspect-ratio="1"
                    width="100%"
                    contain
                  ></v-img>
                </v-carousel-item>
              </v-carousel>
            </template>
            <template v-else>
              <v-img
                v-if="allSizePhotoList[0]"
                class="mainProduct--image"
                :lazy-src="allSizePhotoList[0].tinyPhotoUrl"
                :src="allSizePhotoList[0].originPhotoUrl"
                aspect-ratio="1"
                width="100%"
                contain
              ></v-img>
            </template>
          </div>
          <favoriteBtn
            class="favoriteBtn"
            :defaultStatus="isFavorite"
            :store-id="storeId"
            :provider-id="providerId"
            :product-id="productId"
          ></favoriteBtn>
        </v-col>
        <v-col cols="6">
          <div class="mb-1 cart-item__product--title">{{ productName }}</div>
          <div>
            <span v-if="isUnitSame">{{ perShippingCountText }}</span>
            <template v-else>
              <span class="mr-4 cart-item__product--price">
                {{ perShippingCountText }}
              </span>
              <!-- <span>{{ secondTitle }}</span> -->
            </template>
          </div>
          <div class="store-font-color">今日價格 {{ priceText }}</div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-btn
        v-if="specFiles && specFiles.length > 0"
        color="primary"
        medium
        :loading="loading"
        @click="downloadSpecFile(specFiles[0])"
        >商品相關文件下載</v-btn
      >
      <div
        v-if="isDailyAlert"
        class="mainProduct--dailyprice d-flex align-center"
      >
        <i class="ri-money-dollar-circle-fill mainProduct--icon"></i>
        <span class="">{{ "every-day-price" | t }}</span>
      </div>

      <number-bar
        v-model="quantity"
        title="數量"
        :unit="shipping_unit"
        :fixed="2"
        :rule="(count) => this.countRule({ count, unit: shipping_unit })"
        class="mb-4"
      />

      <v-btn
        depressed
        @click="addToCart"
        class="py-2 mb-4"
        color="primary"
        width="100%"
        >加入購物車</v-btn
      >

      <discount
        v-if="showDiscount"
        :configs="product.discount_config"
        :product="product"
        class="mb-4"
      />

      <p class="my-4 pb-2 border-lightGray-b mb-4 font-weight-title">
        商品描述
      </p>
      <div class="mb-4" v-html="nl2br(product.description)"></div>
    </v-container>
    <!-- <PrimaryModal :show="requestCooperation.switch">
      <v-card-title class="d-flex justify-center">
        <span class="pb-5">請先與 {{ layoutProviderName }} 建立合作</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center">
          <v-btn min-width="100px" color="primary" @click="requestCooperation.switch = false">確認</v-btn>
        </div>
      </v-card-text>
    </PrimaryModal> -->
  </div>
</template>

<script>
import unitText from "@/mixins/unitText.js";
import countMixins from "@/mixins/count.js";
// import PrimaryModal from "components/modal/primaryModal.vue";
export default {
  mixins: [unitText, countMixins],
  components: {
    // PrimaryModal,
    discount: () => import("./discount.vue"),
    "number-bar": () => import("@/modules/base/components/bar/numberBar.vue"),
    favoriteBtn: () => import("@/components/buttons/favoriteBtn"),
  },
  data: () => ({
    product: null,
    quantity: 1,
    isFavorite: false,
    loading: false,
    requestCooperation: {
      switch: false,
    },
  }),
  computed: {
    routeQuery() {
      return window.rootVm.$route.query;
    },
    productId() {
      return this.$route.params.productId;
    },
    shipping_unit() {
      return this.product.shipping_unit;
    },
    calculate_unit() {
      return this.product.calculate_unit;
    },
    specFiles() {
      return this.product.files;
    },
    isUnitSame() {
      if (!this.shipping_unit) return false;
      if (!this.calculate_unit) return false;
      return this.shipping_unit === this.calculate_unit;
    },
    perShippingCountText() {
      return this.perShippingCountTextFunc({
        isUnitSame: this.isUnitSame,
        shipping_unit: this.shipping_unit,
        calculate_unit: this.calculate_unit,
        per_shipping_count: this.product.per_shipping_count,
      });
    },
    priceText() {
      return this.priceTextFunc({
        price: this.product.price,
        unit: this.calculate_unit,
      });
    },
    productName() {
      return this.product.name;
    },
    isDailyAlert() {
      return this.product.daily_alert;
    },
    photoList() {
      if (!Array.isArray(this.product.photos)) {
        return [this.product.photos].filter((v) => v);
      }
      return this.product.photos;
    },
    allSizePhotoList() {
      console.log("allSizePhotoList:", this.photoList);
      return this.photoList
        .map((photo) => {
          if (!photo) return null;
          photo.tinyPhotoUrl = this.$helper.getPhotoUrl(photo, ["tiny"]) || "";
          photo.originPhotoUrl = this.$helper.getPhotoUrl(photo) || "";
          return photo;
        })
        .filter((v) => v);
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    providerId() {
      return this.$route.params.providerId;
    },
    isCooperation() {
      return this.$store.getters["store/isCooperation"];
    },
    showDiscount() {
      if (!this.isCooperation) return false;
      return (
        this.product.discount_type === 1 &&
        this.product.discount_config.length > 0
      );
    },
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
    hasAddToCartQuery() {
      return this.routeQuery && this.routeQuery.addToCart;
    },
  },
  watch: {
    isCooperation: {
      immediate: true,
      handler(newVal, oldVal) {
        // this.$store.dispatch("cart/setShowCart", this.isCooperation);
        if (this.hasAddToCartQuery && (oldVal != undefined || !this.storeId)) {
          if (!newVal || !this.storeId) {
            this.replaceToRegister();
          }
        }
      },
    },
  },
  created() {
    this.$store.dispatch("store/setStoreByApi", {
      vm: this,
      storeId: this.storeId,
      providerId: this.providerId,
    });
    this.readProduct();
    this.getFavorite();
  },
  mounted() {},
  methods: {
    async readProduct() {
      this.$store.dispatch("loading/active");
      try {
        this.product = await this.$api.collection.productApi.read(
          this.productId
        );
      } catch (err) {
        console.error(err);
      } finally {
        if (this.hasAddToCartQuery) {
          this.addToCart({
            name: "cart",
            params: { providerId: this.providerId },
          });
        } else {
          this.$store.dispatch("loading/close");
        }
      }
    },
    async getFavorite() {
      const res = await this.$api.collection.favoriteApi.index(
        this.storeId,
        this.providerId
      );
      this.isFavorite =
        res.findIndex((item) => item.product_id == this.productId) > -1;
    },
    async addToCart(refreshto) {
      // if (!this.isCooperation) {
      //   this.requestCooperation.switch = true
      //   return
      // }
      this.$root.gaLogEvent("商店_點擊_加入購物車");
      this.$store.dispatch("loading/active");
      try {
        const params = {
          products: [
            {
              product_id: this.productId,
              quantity: this.$helper.transformShippingCount({
                count: this.quantity,
                unit: this.shipping_unit,
              }),
            },
          ],
        };
        await this.$api.collection.cartApi.add(
          this.storeId,
          this.providerId,
          params
        );
        await this.readCart();
        this.quantity = null;
        this.$snotify.success("已加入購物車");
      } catch (err) {
        console.error(err);
        this.$snotify.error("加入購物車失敗");
      } finally {
        if (refreshto) {
          this.$router.replace(refreshto);
          setTimeout(() => {
            this.$store.dispatch("loading/close");
          }, 1000);
        } else {
          this.$store.dispatch("loading/close");
        }
      }
    },
    async readCart() {
      const res = await this.$api.collection.cartApi.read(
        this.storeId,
        this.providerId
      );
      this.$store.dispatch(`cart/setCart`, res);
    },
    downloadSpecFile(file) {
      window.open(file.url, "_blank", "download");
    },
    replaceToRegister() {
      const url = window.location.href;
      const origin = window.location.origin;
      this.$router.replace({
        name: "store-register",
        params: { providerId: this.providerId },
        query: { prevUrl: url.split(origin)[1] },
      });
    },
  },
};
</script>

<style lang="sass">
.product-detail
  .mainProduct
    // position: relative
    &--box
      background: #F8F8F8
      border-radius: 0 0 10px 10px
      .v-carousel__controls
        height: 20px!important
        &__item.v-btn.v-btn--icon
          height: 10px
          width: 10px
          border-radius: 50%
          .v-icon
            font-size: 10px!important
    &--image
      border: 1px solid #ECECEC
      border-radius: 10px
    &--dailyprice
      // position: absolute
      // top: 15px
      // left: 10px
      // width: 200px
      // background: #006DE0
      // padding: 8px 20px 6px 16px
      // color: #ffffff
      // border-radius: 99rem
      width: fit-content
      background: #006de0
      padding: 3px 20px 3px 12px
      color: #fff
      border-radius: 99rem
    &--icon
      font-size: 24px
      margin-right: 10px
  .favoriteBtn
    position: absolute
    right: 1rem
    top: 1rem

.cart-item__product
  &--title
    font-size: 20px
    color: #000000
    font-weight: 600
    &--price
      font-size: 16px
      font-weight: 550
      color: #006DE0
</style>
